<template>
  <div>
    <v-btn
      dark
      width="100%"
      height="75px"
      color="#CC0000"
      class="text-capitalize mb-6"
      v-if="$vuetify.breakpoint.smAndUp && !isLoggedIn"
      @click="registerStudentHandler()"
    >
    
       <span style="
          font-family: Roboto Slab;
          font-size: 24px;
          letter-spacing: 0px;
          font-weight: 600;
        ">{{ $t('register_a_student') }}</span>
    </v-btn>
    <div class="pb-6 pt-0">
      <v-card>
        <v-card-title class="pb-0 pt-2" v-if="$vuetify.breakpoint.smAndUp">
          <v-row>
            <v-col cols="12" class="text-center px-4">
              <p class="cardHeading">{{ getDonationDetail.gif_message }}</p>
              <img
                style="margin-top: -18px"
                width="100%"
                :src="getDonationDetail.gif_img"
                alt=""
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-title v-if="$vuetify.breakpoint.xsOnly" style="height: 80px">
          <v-btn
            dark
            width="100%"
            height="50px"
            color="#CC0000"
            class="text-capitalize mb-6"
            v-if="!isLoggedIn"
            @click="registerStudentHandler()"
          >
            <span
              style="
                font-family: Roboto Slab;
                font-size: 20px;
                letter-spacing: 0px;
                font-weight: 600;
              "
              >{{ $t('register_a_student') }}</span
            >
          </v-btn>
        </v-card-title>
        <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
        <v-card-text>
          <div class="px-2">
            <div style="color: #067605; font-size: 26px">
              ${{ getDonationDetail.fundRaised.toLocaleString("en-US") }}
              <span
                style="font-size: 16px; color: #757575"
                v-if="getDonationDetail.Goal"
                >raised of ${{
                  getDonationDetail.Goal.toLocaleString("en-US")
                }}
                goal</span
              >
            </div>
            <v-progress-linear
              :value="getDonationDetail.Percentage"
              color="#4A3290"
              class="my-1"
              height="15"
              style="border-radius: 20px"
            >
              <strong style="color: white"
                >{{ getDonationDetail.Percentage }}%</strong
              >
            </v-progress-linear>
            <v-row>
              <v-col cols="5">
                <div style="font-size: 18px; color: #1d1d1d">
                  {{ getDonationDetail.Donors.toLocaleString("en-US") }}
                  <span class="cardSpan" style="font-size: 16px">Donors</span>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="5" class="text-right">
                <div style="font-size: 18px; color: #1d1d1d">
                  {{ getDonationDetail.daysLeft }}
                  <span class="cardSpan">days left</span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-row>
            <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly && isLoggedIn">
              <div :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'px-4'">
                <v-btn
                  @click="toggleShareFundraiserModal({ show: true }),submitForm()"
                  width="100%"
                  text
                  class="btn text-capitalize"
                  style="border: 2px solid #4a3290"
                >
                  <v-icon
                    :size="$vuetify.breakpoint.smAndUp ? '35' : '25'"
                    color="#4A3290"
                    left
                    >mdi-share-variant-outline</v-icon
                  >
                  <span class="btnSpan"> Social Media Share </span>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="12">
              <div :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'px-4'">
                <v-btn
                  width="100%"
                  dark
                  color="#38A737"
                  class="btn text-capitalize"
                  @click="donateNowHandler()"
                >
                  <v-icon
                    :size="$vuetify.breakpoint.smAndUp ? '35' : '25'"
                    left
                    color="#FFFFFF"
                    >mdi-heart</v-icon
                  >
                  <span class="btnSpan">{{ $t('donate_now') }}</span>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" v-if="isLoggedIn">
              <div :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'px-4'">
                <v-btn
                  width="100%"
                  dark
                  color="#7253CF"
                  class="btn text-capitalize"
                  @click="toggleInviteViaMailModal({ show: true })"
                >
                  <v-icon size="30">mdi-email-outline</v-icon>
                  <span
                    style="
                      font-family: Roboto Slab;
                      font-size: 20px;
                      width: 85%;
                      letter-spacing: 0px;
                    "
                    >{{ $t('share_via_email') }}</span
                  >
                </v-btn>
              </div>
            </v-col>
             <v-col cols="12" v-if="isLoggedIn">
              <div :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'px-4'">
                <v-btn
                  width="100%"
                  dark
                  color="#7253CF"
                  class="btn text-capitalize"
                  @click="toggleInviteViaSmsModal({ show: true })"
                >
                  <img
                    src="@/assets/thingsToDo/textsms1.png"
                    width="30"
                    height="30"
                  />
                  <span
                    style="
                      font-family: Roboto Slab;
                      font-size: 20px;
                      width: 85%;
                      letter-spacing: 0px;
                    "
                    >{{ $t('share_via_text') }}</span
                  >
                </v-btn>
              </div>
            </v-col>
            <v-col
              cols="12"
              v-if="
                $vuetify.breakpoint.smAndUp &&
                this.$route.name === routerURL.publicParentDashboard.name
              "
            >
              <div class="px-4">
                <v-btn
                  @click="toggleShareFundraiserModal({ show: true }),submitForm()"
                  width="100%"
                  height="60px"
                  text
                  class="text-capitalize"
                  style="border: 2px solid #4a3290"
                >
                  <v-icon size="30" color="#4A3290" left
                    >mdi-share-variant-outline</v-icon
                  >
                  <span
                    style="
                      font-family: Roboto Slab;
                      font-size: 20px;
                      color: #4a3290;
                      width: 85%;
                      letter-spacing: 0px;
                    "
                  >
                   {{ $t('social_media_share') }}</span
                  >
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import { ROUTER_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import {
 INVITE_VIA_SOCIAL_SHARE_DETAIL,
} from "@/constants/APIUrls";

export default {
  name: "DonationInfoCard",
  data() {
    return {
      fund_raise_percentage: 50,
      routerURL: ROUTER_URL,
    };
  },
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
      toggleMakeDonationModal: "publicDonationPage/toggleMakeDonationModal",
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
      toggleShareFundraiserModal:
        "publicDonationPage/toggleShareFundraiserModal",
      toggleParentSignUpModal: "publicDonationPage/toggleParentSignUpModal",
      toggleInviteViaMailModal: "publicDonationPage/toggleInviteMail",
      toggleInviteViaSmsModal: "publicDonationPage/toggleInviteSms",
    }),
    donateNowHandler() {
      if (!this.payoutSetupFlag) {
        this.showToast({
          message:
            "The campaign isn't accepting donations yet. Please contact school admin.",
          color: "e",
        });
        return;
      }
      if (this.getCampaign.campaign_detail.is_accepting_payment === false) {
        this.showToast({
          message:
            "The school has completed the wizfit challenge and is no longer accepting donations, thank you for your support.",
          color: "e",
        });
        return;
      }
      let obj = {};
      if (this.getCampaignDetail.type) {
        obj["user_type"] = this.getCampaignDetail.type.type;
        if (this.getCampaignDetail.type.type === "school") {
          obj["name"] = this.getCampaignDetail.school_detail.school_name;
          obj["id"] = this.getCampaignDetail.school_detail.id;
          obj["profile_pic"] = this.getCampaignDetail.school_detail.logo_url;
        } else if (this.getCampaignDetail.type.type === "teacher") {
          obj["name"] = this.getCampaignDetail.teacher_detail.teacher_name;
          obj["id"] = this.getCampaignDetail.teacher_detail.id;
          obj["profile_pic"] =
            this.getCampaignDetail.teacher_detail.profile_pic;
          obj["grade_title"] =
            this.getCampaignDetail.teacher_detail.grade_title;
          obj["school_name"] =
            this.getCampaignDetail.teacher_detail.campaign_school;
        } else if (this.getCampaignDetail.type.type === "student") {
          obj[
            "name"
          ] = `${this.getCampaignDetail.student_detail.student_first_name} ${this.getCampaignDetail.student_detail.student_last_name}`;
          obj["id"] = this.getCampaignDetail.student_detail.id;
          obj["profile_pic"] = null; //not present in current module
          obj["grade_teacher"] =
            this.getCampaignDetail.student_detail.grade_teacher;
          obj["grade_title"] =
            this.getCampaignDetail.student_detail.grade_title;
          obj["school_name"] =
            this.getCampaignDetail.student_detail.school_name;
        }
      } else {
        console.log(
          "getCampaignDetail: ",
          this.getCampaignDetail.campaign_detail
        );
        obj["user_type"] = this.getCampaignDetail.campaign_detail.sub_campaign_type;
        obj["name"] = this.getCampaignDetail.campaign_detail.organization_name;
        obj["id"] = this.getCampaignDetail.campaign_detail.organization_id;
        obj["profile_pic"] =
          this.getCampaignDetail.campaign_detail.organization_logo;
      }
      this.toggleMakeDonationModal({
        show: true,
        autoSelect: _.isEmpty(obj) ? false : obj,
      });
    },
    separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
    },
    registerStudentHandler() {
      if (this.isLoggedIn) {
        this.toggleCreateStudentModal({ show: true, actionType: "add" });
      } else {
        this.toggleParentSignUpModal({ show: true, signUpPageFlag: true });
      }
    },
       submitForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      let formData = {};
      formData["invite_by"] = "student";
      formData["platform"] = "social";
      formData["campaign"] = this.getCampaignDetail.campaign_detail.id;
      formData["student"] = this.student_id;
      Axios.request_POST(
        INVITE_VIA_SOCIAL_SHARE_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );

      this.closeModal();
    },
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    getCampaignDetailType() {
      if (
        this.isLoggedIn &&
        this.$route.name === ROUTER_URL.publicParentDashboard.name
      )
        return false;
      else if (this.getCampaign.type.type === "school") return false;
      else return this.getCampaign.type;
    },
    getDonationDetail() {
      let fund_raised = "";
      let goal = "";
      let percentage = "";
      let donors = "";
      let days_left = "";
      let img = "";
      let message = "";
      if (!this.getCampaignDetail.type) {
        fund_raised =
          this.getCampaignDetail.campaign_detail.original_total_fund_raised_50;
        goal = this.getCampaignDetail.campaign_detail.total_goal_50;
        donors = this.getCampaignDetail.campaign_detail.total_donor;
        img =
          this.getCampaignDetail.campaign_detail
            .original_fund_raise_percentage_img;
        message =
          this.getCampaignDetail.campaign_detail
            .original_fund_raised_status_message;
        percentage =
          this.getCampaignDetail.campaign_detail
            .original_fund_raise_percentage;
      } else if (this.getCampaignDetail.type.type === "school") {
        fund_raised =
          this.getCampaignDetail.school_detail.original_total_fund_raised_50;
        goal = this.getCampaignDetail.school_detail.school_goal_50;
        donors = this.getCampaignDetail.school_detail.total_donor;
        percentage =
          this.getCampaignDetail.school_detail.original_fund_raise_percentage;
        img =
          this.getCampaignDetail.school_detail
            .original_fund_raise_percentage_img;
        message =
          this.getCampaignDetail.school_detail
            .original_fund_raised_status_message;
      } else if (this.getCampaignDetail.type.type === "teacher") {
        fund_raised =
          this.getCampaignDetail.teacher_detail.original_class_fund_raised;
        goal = this.getCampaignDetail.teacher_detail.class_goal;
        percentage =
          this.getCampaignDetail.teacher_detail
            .original_total_class_fund_raised_percentage;
        donors = this.getCampaignDetail.teacher_detail.total_donor;
        img =
          this.getCampaignDetail.teacher_detail
            .original_fund_raise_percentage_img;
        message =
          this.getCampaignDetail.teacher_detail
            .original_fund_raised_status_message;
      } else if (this.getCampaignDetail.type.type === "student") {
        fund_raised = this.getCampaignDetail.student_detail.orignal_fund_raised;
        goal = this.getCampaignDetail.student_detail.student_goal;
        donors = this.getCampaignDetail.student_detail.total_donor;
        percentage =
          this.getCampaignDetail.student_detail.original_fund_raised_percentage;
        img =
          this.getCampaignDetail.student_detail
            .original_fund_raise_percentage_img;
        message =
          this.getCampaignDetail.student_detail
            .original_fund_raised_status_message;
      }
      days_left = this.getCampaignDetail.campaign_detail.remaining_day;
      // console.log(this.getCampaignDetail);
      return {
        fundRaised: fund_raised,
        Goal: goal,
        Percentage: percentage,
        Donors: donors,
        daysLeft: days_left,
        gif_img: img,
        gif_message: message,
      };
    },
    payoutSetupFlag() {
      return this.getCampaign.is_payout_done === null
        ? false
        : this.getCampaign.is_payout_done;
    },
    isLoggedIn() {
      return _.isEmpty(this.userDetails) ? false : this.userDetails;
    },
  },
  mounted() {
    // console.log(
    //   this.getCampaign.campaign_detail.is_accepting_payment,
    //   "is_accepting_payment"
    // );
    // console.log(this.getDonationDetail, "donation Detail");
      this.student_id = this.$route.query.student;
  },
};
</script>
<style scoped>
.v-card {
  border-top: 12px solid #512e96;
}
.cardHeading {
  color: #512e96;
  font-weight: 600;
  font-family: Roboto Slab;
}
.v-btn {
  border-radius: 6px;
}
.cardSpan {
  font-size: 16px;
  color: #757575;
}
.btnSpan {
  font-family: Roboto Slab;
  font-size: 25px;
  font-weight: 600;
  width: 80%;
  letter-spacing: 0px;
}
.btn.v-btn {
  height: 60px;
}
@media only screen and (max-width: 600px) {
  .v-card {
    border-top: 6px solid #512e96;
  }
  .btnSpan {
    font-family: Roboto Slab;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    letter-spacing: 0px;
  }
  .btn.v-btn {
    height: 45px;
  }
}
</style>
